import React, {useState} from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';




 function SubirVideo(props) {


    const { datos, urlVolver, username } = props; //
    const navigate    = useNavigate();
    const [show, setShow] = useState(false); // mostrar modal
    const handleClose = () => setShow(false); // cerrar modal
    const handleShow = () => setShow(true); // mostrar modal

    const [archivo, setArchivo] = useState(null); // imagen subida
    const [cargando, setCargando] = useState(false);
    
    const[inputSubirVideo, setInputSubirVideo] = useState ({
        jugada: "",
        equipo: ""
    });
    
    function handleChangeSubirVideo(event) {
        const { name, value } = event.target;

        setInputSubirVideo(prevInputDos => {
            return {
                ...prevInputDos,
                [name]: value
            };
        });        
    }

    function controlCambioVideo(event){
        const archivoSubido = event.target.files[0]; //la imagen está en la posición 0 del arreglo files
        if(archivoSubido){
            setArchivo(archivoSubido);
        }
    }

    async function subir(event) {
        event.preventDefault();
        setCargando(true);

        let opcion     = inputSubirVideo.jugada;
        let equipo     = inputSubirVideo.equipo;
        let esVideoMp4 = true;

        console.log("datos: ", datos);
        // console.log("opcion: ", opcion);
        // console.log("Equipo: ", equipo);
        // console.log("username: ", username);


        
        // Si no tiene un video
        if(!archivo){  return navigate("/alerta", {state: {titulo:"Falta Video", mensaje:"Debes subir un video.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );  }
         // Verificar si archivo es un File y es un video
        else if (!(archivo instanceof File) || !archivo.type.startsWith("video/")) {
            setArchivo(null); // Resetea el archivo cargado si no es válido
            navigate("/alerta", { state: { titulo: "Archivo Inválido",  mensaje: "El archivo seleccionado no es un video válido.", urlMensaje: urlVolver, nombreBoton: "Volver" } });
        }
        else if(typeof inputSubirVideo.jugada === 'undefined' || inputSubirVideo.jugada === ""){
            setArchivo(null);
            return navigate("/alerta", {state: {titulo:"Falta Jugada", mensaje:"Selecciona una jugada.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );
        }
        else if(typeof inputSubirVideo.equipo === 'undefined' || inputSubirVideo.equipo === ""){
            setArchivo(null);
            return navigate("/alerta", {state: {titulo:"Falta Equipo", mensaje:"Selecciona un equipo.", urlMensaje: urlVolver, nombreBoton:"Volver"} } );
        }
        // iniciar la carga multipart
        else{
            console.log("1. Iniciando carga multipart");


            const blob = archivo;
  
            const chunkSize = 5 * 1024 * 1024; // fragmentos de 5MB
            const totalChunks = Math.ceil(blob.size / chunkSize); // calculamos el tamaño de fragmentos a subir.
            
            console.log("chucksize: ", chunkSize);
            console.log("blob size: ", blob.size);
            console.log("total chunks: ", totalChunks);

            //1. Iniciar carga multipart y obtener uploadId
            let uploadId;
            let key;
            let idVideo;
            try {
                console.log("1. Iniciando carga multipart.");
                const initResponse = await axios.post('/iniciar-carga-multipart', { totalChunks, opcion, esVideoMp4, equipo, username }, {
                params: { datos: datos }
                });

                // Guardamos datos obtenidos del primer paso.
                uploadId = initResponse.data.uploadId;
                key      = initResponse.data.key;
                idVideo  =  initResponse.data.idVideo; // creado a partir de v4 en servidor. id único
            } 
            catch (error) {
                console.error('Error iniciando carga multipart', error);
                setCargando(false);
                return;
            }


            const parts = [];
  
            //2. Subir cada fragmento
            for (let i = 0; i < totalChunks; i++) {
                const start = i * chunkSize;
                const end   = Math.min(start + chunkSize, blob.size);
                const chunk = blob.slice(start, end);
    
                const formData = new FormData();
                
                const extension = 'mp4'; // Determinar la extensión del archivo según el dispositivo
                formData.append('videoChunk', chunk, `recording-${i + 1}.${extension}`);
                formData.append('chunkNumber', i + 1);
                formData.append('totalChunks', totalChunks);
                formData.append('opcion', opcion);
                formData.append('uploadId', uploadId);
                formData.append('key', key);
    
                try {
                console.log("2. Subida de fragmentos.");
                const response = await axios.post('/subida-video-camarografo-chunk', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    params: { datos: datos }
                });
    
                if (response.data.status !== 200) {
                    throw new Error('Error al subir el fragmento');
                }
    
                parts.push({ ETag: response.data.ETag, PartNumber: i + 1 });
                } 
                catch (error) {
                console.error(`Error uploading chunk ${i + 1}`, error);
                setCargando(false);
                return;
                }
            }


            //3. Enviar petición para ensamblar los fragmentos
            try {
                console.log("3. Iniciando ensamble final.");

                let datosVideo = {
                    ancho: 1920,
                    alto: 1080,
                    fps: 60,
                    sampleRate: 44100,
                    videoBitsPerSecond: 5, // Mbps
                    audioBitsPerSecond: 128, // Kbps
                    esVertical: false
                }

                const response = await axios.post('/ensamblar-fragmentos', { totalChunks, uploadId, parts, key, opcion, equipo, esVideoMp4, idVideo }, {
                params: { datos: datos, datosVideo: datosVideo}
                });
    
                if (response.data.status === 200) {
                window.location.reload();
                alert('Video subido exitosamente.');
                } 
                else {
                throw new Error('Error al ensamblar los fragmentos');
                }
            } 
            catch (error) { console.error('Error ensamblando los fragmentos', error); } 
            finally { 
                setCargando(false);  
                setArchivo(null);
            }
        }
    }

    


    return(
        <div>
            <Button  variant="warning w-100 mt-1 py-2" onClick={handleShow}>
                Subir video
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Subir video</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                <p>Sube un video horizontal  <strong>preferiblemente de 30 segundos</strong> .</p>
                
                    

                  
                     
                     <form>
                        {/* jugada opción */}
                        <div className="form-floating mb-3">
                            <select onChange={handleChangeSubirVideo} value={inputSubirVideo.jugada} name="jugada" className="form-select" id="floatingJugada">
                                <option value="">Seleccionar jugada</option>
                                <option value="atajada">atajada</option>
                                <option value="gol">gol</option>
                                <option value="oportunidad">oportunidad</option>
                            </select>
                            <label htmlFor="floatingJugada">Jugada</label>
                        </div>

                        {/* seleccionar equiop */}
                        <div className="form-floating mb-3">
                            <select onChange={handleChangeSubirVideo} value={inputSubirVideo.equipo} name="equipo" className="form-select" id="floatingEquipo">
                                <option value="">Seleccionar equipo</option>
                                <option value={datos.nombreEquipoUno}>{datos.nombreEquipoUno}</option>
                                <option value={datos.nombreEquipoDos}>{datos.nombreEquipoDos}</option>
                            </select>
                            <label htmlFor="floatingEquipo">Equipo</label>
                        </div>

                        {/* Inpud video */}
                        <div className="form-floating mb-3">
                            <input onChange={controlCambioVideo} className="form-control" type="file" id="formFile" /> 
                            <label htmlFor="floatingInput">Video</label>
                        </div>

                   
                        <button type="submit" className="btn btn-primary w-100" onClick={subir} >Subir video</button>
                    </form>
                </Modal.Body>

                {/* Footer */}
                <Modal.Footer>
                    <Button className="w-100" variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>

                {/* zona cargando */}
            {cargando && (
            <div className="overlay">
            <div className="loader"></div>
            <p className="loadingText">Subiendo video, por favor espere...</p>
            </div>
            )}
            </Modal>


            
        </div>
    );
    
}


export default SubirVideo;