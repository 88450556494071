import '../estilos/partials.css';
import {useLocation,  useParams, useNavigate, Link} from 'react-router-dom';
import React, {useState, useEffect} from "react";
import axios from "axios";
import ContenedorFiltros from '../paginas/usuario/contenedorFiltros.jsx';
import ContenedorFiltrosGlobal from '../paginas/webGlobal/contenedorFiltrosGlobal.jsx';


function HeaderGlobal(){

    const navigate    = useNavigate();
    
    const location    = useLocation(); // cuando no se envía nada en state, location,state es null
    const routeParams = useParams();
    let {username, pagina, tipoBusqueda, cualFiltro, contenido} = routeParams; // capturamos para paginación

    const [infoUsuarioLocal, setInfoLocal]= useState(null); // Variables localstorage
    const [avatar, setAvatar] = useState("");
    const [videosMostrar, setVideosMostrar] = useState(null);
    const [cargando, setCargando] = useState(true);
    const [siguientePagina, setSiguientePagina] = useState(null);
    const [paginaAnterior, setPaginaAnterior] = useState(null); 
    const [cantidadDePaginas, setCantidadDePaginas] = useState(null);
    const [equipos, setEquipos]     = useState([]);
    const [estadios, setEstadios]   = useState([]);
    const [torneos, setTorneos]     = useState([]);
    const [busquedaMostrada, setBusquedaMostrada] = useState(null);


    const [paginaActual, setPaginaActual] = useState(() => {
        return parseInt(localStorage.getItem("paginaActual")) || 1;
      });
      const [tipoBusquedaNuevo, setTipoBusquedaNuevo] = useState(() => {
        return localStorage.getItem("tipoBusquedaNuevo") || "NA";
      });
      const [cualFiltroNuevo, setCualFiltroNuevo] = useState(() => {
        return localStorage.getItem("cualFiltroNuevo") || "NA";
      });
      const [contenidoNuevo, setContenidoNuevo] = useState(() => {
        return localStorage.getItem("contenidoNuevo") || "NA";
      });








      useEffect(() => {
        
        async function obtenerDatos() {

            // Array de funciones a ejecutar
            const funciones = [

                async function obtenerEquipos() {
                    const response = await axios.post("/obtener-equipo-para-editar");
                    setEquipos(response.data.equipos);
                },

                async function obtenerEstadios() {
                    const response = await axios.post("/obtener-estadios-para-editar");
                    setEstadios(response.data.estadios);
                },

                async function obtenerTorneos() {
                    const response = await axios.post("/obtener-torneos", {
                        params: { deporte: "futbol" }
                    });
                    if (response.status === 200) {
                        setTorneos(response.data.torneos);
                    }
                },

               
            ];

            // Ejecuta todas las funciones en paralelo y espera a que todas terminen
            try {

                await Promise.all(funciones.map(funcion => funcion()));
            } catch (error) {
                console.error('Error al obtener datos:', error);
            } finally {
                //setCargando(false); // Cambia a false solo cuando todas las funciones terminan
            }
        }

        obtenerDatos(); // Ejecuta la función al montar el component

        return ()=> {
            localStorage.setItem("paginaActual",1);
            
        }
    }, []);







    let urlVolver = "/";

   

    async function iniciarsesion(){
   
        var urlAver = "/iniciar-sesion";
        navigate(urlAver);
        window.location.reload();
    }
    async function registrar(){
   
        var urlAver = "/registrar";
        navigate(urlAver);
        window.location.reload();
    }
    
    async function ejectuarBusqueda(datos){
        
        let tipoDeBusqueda   = datos.tipo;      // búsqueda o filtro
        let busquedaForm = datos.busqueda       // Lo que el usuario escribe en el input
        .toLowerCase()                          // Convertir a minúsculas
        .trim()                                 // Eliminar espacios al inicio y al final
        .replace(/\s+/g, '-');                  // Reemplazar espacios intermedios por guiones
        let cualFiltroForm   = datos.cualFiltro;      // cual filtor selecciona el usuario
        let contenidoFiltroF = datos.contenidoFiltro; // contenido del filtro seleccionado
        


        // pagina: paginaActual, tipoBusqueda: tipoBusquedaNuevo, cualFiltro: cualFiltroNuevo, contenido: contenidoNuevo



        if(tipoDeBusqueda==="busqueda"){


            // actualizar variables de localstorge
            localStorage.setItem("tipoBusquedaNuevo", tipoDeBusqueda); // busquieda 
            localStorage.setItem("contenidoNuevo", busquedaForm); // lo que el usuario escribe 

            let urlAver = "/videos";
            navigate(urlAver);
            window.location.reload();
        }
        else if(tipoDeBusqueda==="filtro"){

            //actualizar variables de localstorage
            localStorage.setItem("tipoBusquedaNuevo",tipoDeBusqueda); // actualizamos a filtro
            localStorage.setItem("contenidoNuevo", contenidoFiltroF); // actualizamos el contenido 
            localStorage.setItem("cualFiltroNuevo", cualFiltroForm);

            let urlAver = "/videos";
            navigate(urlAver);
            window.location.reload();
        }
    }
    



    return(
        <div>
           <nav  class="navbar navbar-expand-sm navbar-dark py-2 bg-body-tertiary border-bottom" aria-label="Third navbar example">
                <div class="container-fluid d-flex flex-wrap ">
                    <ul class="barra me-auto mb-2 mb-sm-0">
                        <li className="nav-item dropdown"><a className="nav-link  text-dark" href="/videos" > Ver videos </a> </li>
                        <li class="nav-item"><a className="nav-link text-dark" href="/ver-videos/futbol">Torneos</a></li>
                        <li class="nav-item"><a className="nav-link text-dark" href="/paga-y-graba">Grabar</a></li>
                    </ul>
                    <div id='contenedorSegundaParteHeader' className="col-md-4 text-md-end text-center headerFinal">
                            {/* Segunda parte del menú */}
                        <button className=" btn text-dark btn-outline-w me-3 border-dark  w-100"  type="button" onClick={iniciarsesion}>
                            Iniciar Sesion
                        </button>
                        <button className="btn btn-primary w-100 " type="button" onClick={registrar}>
                            Registrar
                        </button>
                    </div>
                </div>
            </nav>
            <header  className="navbar navbar-expand-sm navbar-dark border-bottom py-3 mb-4 px-3" aria-label="Third navbar example">
                <div class="container-fluid d-flex flex-wrap justify-content-center">
                    {/* Imagen */}
                    <a className="navbar-brand d-flex align-items-center mb-3 mb-lg-0 me-lg-auto link-body-emphasis text-decoration-none" href="/" ><img src="/imagenes/marca/clvhorizontala.png" alt="" height="50px" /></a>
    
    
                    {/* Contenedor para búsqueda y filtro */}
                    <ContenedorFiltrosGlobal urlVolver={urlVolver} usuario={username} equipos={equipos} estadios={estadios} torneos={torneos} cualBusqueda={ejectuarBusqueda} />
                </div>
            </header>

        </div>
    )
}

export default HeaderGlobal;